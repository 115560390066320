import Button from "@app/components/button";
import ConfirmModal from "@app/components/confirm-modal";
import Input from "@app/components/input";
import Pagination from "@app/components/pagination";
import { Select } from "@app/components/select/select";
import { NormalSelectOptions } from "@app/components/select/select.type";
import { addToast } from "@app/components/toast/toast.service";
import CreateVPSModal from "@app/components/vps-modal/vps-modal.component";
import {
  DEFAULT_PAGE,
  DEFAULT_TOTAL_PAGES,
  SystemMessage,
} from "@app/constants";
import { VPS_OS, VPS_Status, VPS_StatusLabel } from "@app/constants/vps.const";
import ComputerConfigService from "@app/services/http/computer.config.service";
import VPSService from "@app/services/http/vps.service";
import { openPortalDialog } from "@app/services/modal.service";
import { ComputerConfig, VPS } from "@app/types";
import useForceUpdate from "@core/hooks/use-force-update.hook";
import useObservable from "@core/hooks/use-observable.hook";
import { ChangeEvent, useEffect, useState } from "react";
import { debounceTime, Subject } from "rxjs";

function VpsManagement() {
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [totalPages, setTotalPages] = useState(DEFAULT_TOTAL_PAGES);
  const [vps, setVPS] = useState<VPS[]>([]);

  const [config, setConfig] = useState("");
  const [status] = useState<VPS_Status>();
  const [os] = useState<VPS_OS>();
  const [vpsId, setVpsId] = useState("");
  const [filterConfigOptions, setFilterConfigOptions] =
    useState<NormalSelectOptions>([]);

  const vpsIdSubject = new Subject<string>();

  const [update, forceUpdate] = useForceUpdate();

  const { subscribeOnce, subscribeUntilDestroy } = useObservable();

  useEffect(() => {
    vpsIdSubject.pipe(debounceTime(300)).subscribe((data) => {
      setVpsId(data);
    });
  }, [vpsIdSubject]);

  useEffect(() => {
    subscribeUntilDestroy(ComputerConfigService.getConfigs(), (configs) => {
      if (configs.length) {
        const configsDropdown: NormalSelectOptions = configs.map((config) => ({
          label: config.config_code,
          value: config._id,
        }));

        configsDropdown.unshift({
          label: "Tất cả",
          value: "",
        });

        setFilterConfigOptions(configsDropdown);
      } else {
        setFilterConfigOptions([]);
      }
    });
  }, []);

  useEffect(() => {
    subscribeUntilDestroy(
      VPSService.getVPS(page, config, status, os, vpsId),
      (res) => {
        if (res.data.length) {
          setVPS(res.data);
          setTotalPages(res.total_page);
        } else {
          setVPS([]);
          setTotalPages(DEFAULT_TOTAL_PAGES);
        }
      }
    );
  }, [update, page, config, status, os, vpsId]);

  const handleCreatePackage = () => {
    const createPackageModalObs = openPortalDialog(CreateVPSModal);

    createPackageModalObs.afterClosed().subscribe((data) => {
      if (data?.vps) {
        addToast({ text: SystemMessage.ADD_SUCCESSFULLY });
        forceUpdate();
      }
    });
  };

  const handleDeleteVPS = (vpsId: string) => {
    const deletePackageObs = openPortalDialog(ConfirmModal, {
      message: "Bạn có chắc chắn xóa vps này không?",
    });

    deletePackageObs.afterClosed().subscribe((data) => {
      if (data?.isAccept) {
        subscribeOnce(VPSService.deleteVPS(vpsId), () => {
          addToast({ text: SystemMessage.DELETE_SUCCESSFULLY });
          forceUpdate();
        });
      }
    });
  };

  const handleUpdateVPS = (vps: VPS) => {
    const createPackageModalObs = openPortalDialog(CreateVPSModal, {
      vps,
    });

    createPackageModalObs.afterClosed().subscribe((data) => {
      if (data?.vps) {
        addToast({ text: SystemMessage.ADD_SUCCESSFULLY });
        forceUpdate();
      }
    });
  };

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const handleFilterConfigChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setConfig(e.target.value as any);
  };

  const onVpsIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    vpsIdSubject.next(e.target.value);
  };

  return (
    <div className="w-full mx-auto mt-4 pb-4">
      <div className="flex items-center justify-between pb-2 px-2 border-b border-[#409EFF]">
        <div className="flex items-center gap-2">
          <Input
            className="mt-0 max-w-[320px]"
            placeholder="Nhập ID VPS"
            onChange={onVpsIdChange}
          />
          <Select
            width={110}
            className="h-6 !mt-0"
            placeholder="Cấu hình"
            options={filterConfigOptions}
            value={config}
            defaultValue={-1}
            onChange={handleFilterConfigChange}
          />
        </div>
        <div className="flex justify-end">
          <Button
            label="Tạo VPS"
            width="fit-content"
            className="px-2 text-sm"
            onClick={handleCreatePackage}
          />
        </div>
      </div>
      <div className="px-2 overflow-x-auto mt-2">
        <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal">
            <thead className="text-white">
              <tr className="border-b-2 border-gray-200 bg-[#409EFF] text-sm">
                <th className="p-3 text-left w-52">Mã Cấu Hình / ID VPS</th>
                <th className="p-3 text-left w-72">Tên cấu hình</th>
                <th className="p-3 text-left w-72">User</th>
                <th className="p-3 text-left w-72">Pass</th>
                <th className="p-3 text-left w-72">OS</th>
                <th className="p-3 text-left w-72">SSH/IP_REMOTE</th>
                <th className="p-3 text-left w-52">Trạng Thái</th>
                <th className="p-3 text-left w-52">Hành động</th>
              </tr>
            </thead>
            <tbody>
              {!!vps.length &&
                vps.map((item) => (
                  <tr
                    key={item._id}
                    className="border-b border-gray-200 text-sm hover:bg-[#51b83c2c]"
                  >
                    <td className="p-3">
                      {(item.config as ComputerConfig).config_code} / {item._id}
                    </td>
                    <td className="p-3">
                      {(item.config as ComputerConfig).config_name}
                    </td>
                    <td className="p-3">{item.user}</td>
                    <td className="p-3">{item.pass}</td>
                    <td className="p-3">{item.os}</td>
                    <td className="p-3">
                      {item.os === VPS_OS.UBUNTU ? item.ssh : item.ip_remote}
                    </td>
                    <td className="p-3">{VPS_StatusLabel[item.status]}</td>

                    <td className="p-3">
                      <Button
                        label="Xóa"
                        width="fit-content"
                        className="px-2 text-sm"
                        onClick={() => handleDeleteVPS(item._id)}
                      />
                      <Button
                        label="Sửa"
                        width="fit-content"
                        className="px-2 text-sm mt-2"
                        onClick={() => handleUpdateVPS(item)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {!vps.length && (
            <div className="flex justify-center">Không có dữ liệu</div>
          )}
        </div>
      </div>
      {totalPages > 0 && (
        <div className="flex justify-center mt-3">
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onPageChange={onPageChange}
          />
        </div>
      )}
    </div>
  );
}

export default VpsManagement;
