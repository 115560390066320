import * as Yup from "yup";

export const createComputerConfigValidationSchema = Yup.object().shape({
  monthly_rental_price: Yup.number()
    .required("Giá không được để trống")
    .integer("Số tiền không hợp lệ"),
  config_code: Yup.string().required("Mã cấu hình không được để trống"),
  config_name: Yup.string().required("Tên cấu hình không được để trống"),
  cpu: Yup.string().required("CPU không được để trống"),
  gpu: Yup.string().required("GPU không được để trống"),
  ram: Yup.string().required("RAM không được để trống"),
  ssd: Yup.string().required("SSD không được để trống"),
  network_speed: Yup.string().required("Tốc độ mạng không được để trống"),
  ipv4: Yup.string().required("Địa chỉ IPv4 không được để trống"),
  os: Yup.string().required("Hệ điều hành không được để trống"),
  data_center: Yup.string().required("Trung tâm dữ liệu không được để trống"),
});
