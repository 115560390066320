import { VPS_OS, VPS_Status } from "@app/constants/vps.const";
import * as Yup from "yup";

// Yup validation schema
export const createVpsValidationSchema = Yup.object()
  .shape({
    ip_remote: Yup.string().nullable(),
    ssh: Yup.string().nullable(),
    user: Yup.string().required("Tên người dùng không được để trống"),
    pass: Yup.string().required("Mật khẩu không được để trống"),
    os: Yup.string()
      .oneOf(Object.values(VPS_OS), "Hệ điều hành không hợp lệ")
      .required("Hệ điều hành không được để trống"),
    status: Yup.string()
      .oneOf(Object.values(VPS_Status), "Trạng thái không hợp lệ")
      .default(VPS_Status.ALREADY),
    config: Yup.string()
      .required("Cấu hình máy tính không được để trống")
      .matches(/^[0-9a-fA-F]{24}$/, "Cấu hình phải là ObjectId hợp lệ"),
  })
  .test(
    "ip-or-ssh-required",
    "Phải nhập ít nhất một trong IP Remote hoặc SSH",
    function (value) {
      const { ip_remote, ssh } = value; // Lấy giá trị của ip_remote và ssh

      if (!ip_remote && !ssh) {
        return this.createError({
          path: "ip_remote", // Báo lỗi tại trường ip_remote hoặc ssh
          message: "Phải nhập ít nhất một trong IP Remote hoặc SSH",
        });
      }

      return true;
    }
  );
