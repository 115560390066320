import { DEFAULT_PAGE } from "@app/constants";
import { VPS_Status } from "@app/constants/vps.const";
import { OrderVpsResponse } from "@app/types/order.vps.type";
import HttpService from "@core/services/http/http.service";

class _OrderVpsService {
  public getOrdersVps(
    page = DEFAULT_PAGE,
    search = "",
    username = "",
    user = "",
    fromDate = "",
    toDate = "",
    status?: VPS_Status
  ) {
    const queryParams: any = {
      page,
      config_code: search,
      username,
      user,
      fromDate,
      toDate,
    };

    if (typeof status === "string" && status !== VPS_Status.ALREADY) {
      queryParams.status = status;
    }

    return HttpService.get<OrderVpsResponse>("/orders-vps", {
      queryParams,
    });
  }

  public getExcelFileOrders(
    search = "",
    username = "",
    fromDate = "",
    toDate = "",
    status?: VPS_Status
  ) {
    const queryParams: any = {
      search,
      username,
      fromDate,
      toDate,
    };

    if (typeof status === "string" && status !== VPS_Status.ALREADY) {
      queryParams.status = status;
    }

    return HttpService.requestDownload("/orders-vps/orders_file", queryParams);
  }
}

const OrderVpsService = new _OrderVpsService();

export default OrderVpsService;
