import * as RemoveIconThin from "@assets/images/svg/remove-icon-thin.svg";
import * as EyeIcon from "@assets/images/svg/eye.svg";
import * as EyeSlashIcon from "@assets/images/svg/eye-slash.svg";
import * as Background from "@assets/images/background.jpg";
import * as LogoIcon from "@assets/images/svg/logo.svg";
import * as LogoTextIcon from "@assets/images/svg/logo-text.svg";
import * as LogoIrenderCoinIcon from "@assets/images/svg/logo-irender-coin.svg";
import * as AvatarDefaultIcon from "@assets/images/svg/avatar-default.svg";
import * as NvidiaIcon from "@assets/images/svg/nvidia.svg";
import * as MoneyIcon from "@assets/images/svg/money.svg";
import * as Group from "@assets/images/group.png";
import * as BankBuilding from "@assets/images/bank-building.png";
import * as Money from "@assets/images/money.png";
import * as Category from "@assets/images/categories.png";
import * as Package from "@assets/images/package.png";
import * as Computer from "@assets/images/computer.png";
import * as Log from "@assets/images/log.png";
import * as Clipboard from "@assets/images/clipboard.png";
import * as LogoPcRender from "@assets/images/logo.jpg";
import * as MenuIcon from "@assets/images/menu.png";
import * as IPAddressIcon from "@assets/images/ip-address.png";
import * as Config from "@assets/images/config.png";
import * as VPS from "@assets/images/vps.png";
import * as OrderVps from "@assets/images/OrderVps.png";

export const Images = {
  RemoveIconThin,
  EyeIcon,
  EyeSlashIcon,
  Background,
  LogoIcon,
  LogoTextIcon,
  LogoIrenderCoinIcon,
  AvatarDefaultIcon,
  NvidiaIcon,
  MoneyIcon,
  Group,
  BankBuilding,
  Money,
  Category,
  Package,
  Computer,
  Log,
  Clipboard,
  LogoPcRender,
  MenuIcon,
  IPAddressIcon,
  Config,
  VPS,
  OrderVps,
};
