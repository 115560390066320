import Button from "@app/components/button";
import CreateComputerConfigModal from "@app/components/computer-config-modal/computer-config-modal.component";
import ConfirmModal from "@app/components/confirm-modal";
import { addToast } from "@app/components/toast/toast.service";
import { SystemMessage } from "@app/constants";
import ComputerConfigService from "@app/services/http/computer.config.service";
import { openPortalDialog } from "@app/services/modal.service";
import { ComputerConfig } from "@app/types/computer.config.type";
import { formatMoneyVN } from "@app/utils/util";
import useForceUpdate from "@core/hooks/use-force-update.hook";
import useObservable from "@core/hooks/use-observable.hook";
import { useEffect, useState } from "react"; 

function CategoryManagement() {
  const [computerConfigs, setComputerConfigs] = useState<ComputerConfig[]>([]);

  const [update, forceUpdate] = useForceUpdate();
  const { subscribeOnce, subscribeUntilDestroy } = useObservable();

  useEffect(() => {
    subscribeUntilDestroy(ComputerConfigService.getConfigs(), (configs) => {
      if (configs.length) {
        setComputerConfigs(configs);
      } else {
        setComputerConfigs([]);
      }
    });
  }, [update]);

  const handleCreateConfig = () => {
    const createConfigModalObs = openPortalDialog(CreateComputerConfigModal);

    createConfigModalObs.afterClosed().subscribe((data) => {
      if (data?.config) {
        addToast({ text: SystemMessage.ADD_SUCCESSFULLY });
        forceUpdate();
      }
    });
  };

  const handleDeleteConfig = (configId: string) => {
    const deleteCategoryObs = openPortalDialog(ConfirmModal, {
      message: "Bạn có chắc chắn xóa cấu hình này không?",
    });

    deleteCategoryObs.afterClosed().subscribe((data) => {
      if (data?.isAccept) {
        subscribeOnce(
          ComputerConfigService.deleteOrUpdateComputerConfig(configId, {
            is_deleted: true,
          }),
          () => {
            addToast({ text: SystemMessage.DELETE_SUCCESSFULLY });
            forceUpdate();
          }
        );
      }
    });
  };

  const handleUpdateConfig = (config: ComputerConfig) => {
    const createConfigModalObs = openPortalDialog(CreateComputerConfigModal, {
      config,
    });

    createConfigModalObs.afterClosed().subscribe((data) => {
      if (data?.config) {
        addToast({ text: SystemMessage.UPDATE_SUCCESSFULLY });
        forceUpdate();
      }
    });
  };

  return (
    <div className="w-full mx-auto mt-4 pb-4">
      <div className="pb-2 px-2 border-b border-[#409EFF]">
        <div className="flex justify-end">
          <Button
            label="Thêm Cấu Hình"
            width="fit-content"
            className="px-2 text-sm"
            onClick={handleCreateConfig}
          />
        </div>
      </div>
      <div className="px-2 overflow-x-auto mt-2">
        <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
          <table className="min-w-full leading-normal">
            <thead className="text-white">
              <tr className="border-b-2 border-gray-200 bg-[#409EFF] text-sm">
                <th className="p-3 text-left">Mã Cấu Hình</th>
                <th className="p-3 text-left">Tên Cấu Hình</th>
                <th className="p-3 text-left">Giá/tháng</th>
                <th className="p-3 text-left">CPU</th>
                <th className="p-3 text-left">GPU</th>
                <th className="p-3 text-left">RAM</th>
                <th className="p-3 text-left">SSD</th>
                <th className="p-3 text-left">Tốc độ mạng</th>
                <th className="p-3 text-left">IPv4</th>
                <th className="p-3 text-left">Hệ điều hành</th>
                <th className="p-3 text-left">Trung tâm dữ liệu</th>
                <th className="p-3 text-left">Đã xóa?</th>
                <th className="p-3 text-left">Hành động</th>
              </tr>
            </thead>
            <tbody>
              {!!computerConfigs.length &&
                computerConfigs.map((config) => (
                  <tr
                    key={config._id}
                    className="border-b border-gray-200 text-sm hover:bg-[#51b83c2c]"
                  >
                    <td className="p-3">{config.config_code}</td>
                    <td className="p-3">{config.config_name}</td>
                    <td className="p-3">
                      {formatMoneyVN(config.monthly_rental_price)} VNĐ
                    </td>
                    <td className="p-3">{config.cpu}</td>
                    <td className="p-3">{config.gpu}</td>
                    <td className="p-3">{config.ram}</td>
                    <td className="p-3">{config.ssd}</td>
                    <td className="p-3">{config.network_speed}</td>
                    <td className="p-3">{config.ipv4}</td>
                    <td className="p-3">{config.os}</td>
                    <td className="p-3">{config.data_center}</td>
                    <td className="p-3">
                      {config.is_deleted ? "Có" : "Không"}
                    </td>
                    <td className="p-3">
                      <Button
                        label="Xóa"
                        width="fit-content"
                        className="px-2 text-sm"
                        onClick={() => handleDeleteConfig(config._id)}
                      />
                      <Button
                        label="Sửa"
                        width="fit-content"
                        className="px-2 text-sm mt-2"
                        onClick={() => handleUpdateConfig(config)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {!computerConfigs.length && (
            <div className="flex justify-center">Không có dữ liệu</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CategoryManagement;
