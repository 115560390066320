import { PortalDialogProps } from "@app/services/modal.service";
import Modal from "../modal/modal.component";
import { Form, Formik, FormikContextType } from "formik";
import { FormControl } from "../form-control";
import Input from "../input";
import Button from "../button";
import { ComputerConfigInitialValues } from "@app/types";
import { createRef } from "react";
import useObservable from "@core/hooks/use-observable.hook";
import { createComputerConfigValidationSchema } from "@app/validations";
import ComputerConfigService from "@app/services/http/computer.config.service";

function CreateComputerConfigModal({
  portalDialogRef,
  portalData,
}: PortalDialogProps) {
  const formRef = createRef<FormikContextType<ComputerConfigInitialValues>>();

  const initialValues = {
    monthly_rental_price: portalData?.config?.monthly_rental_price || 0,
    config_code: portalData?.config?.config_code || "",
    config_name: portalData?.config?.config_name || "",
    cpu: portalData?.config?.cpu || "",
    gpu: portalData?.config?.gpu || "",
    ram: portalData?.config?.ram || "",
    ssd: portalData?.config?.ssd || "",
    network_speed: portalData?.config?.network_speed || "",
    ipv4: portalData?.config?.ipv4 || "",
    os: portalData?.config?.os || "",
    data_center: portalData?.config?.data_center || "",
  };

  const { subscribeOnce } = useObservable();

  const handleSubmit = (values: ComputerConfigInitialValues) => {
    if (portalData?.config) {
      subscribeOnce(
        ComputerConfigService.deleteOrUpdateComputerConfig(
          portalData.config._id,
          values
        ),
        (config) => {
          portalDialogRef.close({ config });
        }
      );
    } else {
      subscribeOnce(
        ComputerConfigService.createComputerConfig(values),
        (config) => {
          portalDialogRef.close({ config });
        }
      );
    }
  };

  return (
    <Modal onCancel={portalDialogRef.close} buttonCancelInChildren>
      <div className="w-[300px] sm:w-[500px] p-5">
        <div className="font-bold text-xl">Cấu hình</div>
        <Formik
          initialValues={initialValues}
          validationSchema={createComputerConfigValidationSchema}
          innerRef={formRef}
          onSubmit={handleSubmit}
          validateOnChange
          validateOnBlur
        >
          <Form>
            <div className="flex items-center justify-between">
              {/* config code */}
              <div className="mt-3">
                <div className="text-sm">Mã Cấu Hình</div>
                <FormControl name="config_code">
                  <Input
                    className="mt-1 !max-w-none text-sm"
                    errorClassName="text-sm"
                  />
                </FormControl>
              </div>

              {/* config name */}
              <div className="mt-3">
                <div className="text-sm">Tên Cấu Hình</div>
                <FormControl name="config_name">
                  <Input
                    className="mt-1 !max-w-none text-sm"
                    errorClassName="text-sm"
                  />
                </FormControl>
              </div>
            </div>

            <div className="flex items-center justify-between">
              {/* CPU */}
              <div className="mt-3">
                <div className="text-sm">CPU</div>
                <FormControl name="cpu">
                  <Input
                    className="mt-1 !max-w-none text-sm"
                    errorClassName="text-sm"
                  />
                </FormControl>
              </div>

              {/* GPU */}
              <div className="mt-3">
                <div className="text-sm">GPU</div>
                <FormControl name="gpu">
                  <Input
                    className="mt-1 !max-w-none text-sm"
                    errorClassName="text-sm"
                  />
                </FormControl>
              </div>
            </div>

            <div className="flex items-center justify-between">
              {/* RAM */}
              <div className="mt-3">
                <div className="text-sm">RAM</div>
                <FormControl name="ram">
                  <Input
                    className="mt-1 !max-w-none text-sm"
                    errorClassName="text-sm"
                  />
                </FormControl>
              </div>

              {/* SSD */}
              <div className="mt-3">
                <div className="text-sm">SSD</div>
                <FormControl name="ssd">
                  <Input
                    className="mt-1 !max-w-none text-sm"
                    errorClassName="text-sm"
                  />
                </FormControl>
              </div>
            </div>

            <div className="flex items-center justify-between">
              {/* monthy rental price */}
              <div className="mt-3">
                <div className="text-sm">Giá theo tháng</div>
                <FormControl name="monthly_rental_price">
                  <Input
                    className="mt-1 !max-w-none text-sm"
                    errorClassName="text-sm"
                    type="number"
                  />
                </FormControl>
              </div>
              {/* Network Speed */}
              <div className="mt-3">
                <div className="text-sm">Tốc độ mạng</div>
                <FormControl name="network_speed">
                  <Input
                    className="mt-1 !max-w-none text-sm"
                    errorClassName="text-sm"
                  />
                </FormControl>
              </div>
            </div>

            {/* IPv4 */}
            <div className="mt-3">
              <div className="text-sm">IPv4</div>
              <FormControl name="ipv4">
                <Input
                  className="mt-1 !max-w-none text-sm"
                  errorClassName="text-sm"
                />
              </FormControl>
            </div>

            {/* OS */}
            <div className="mt-3">
              <div className="text-sm">Hệ điều hành</div>
              <FormControl name="os">
                <Input
                  className="mt-1 !max-w-none text-sm"
                  errorClassName="text-sm"
                />
              </FormControl>
            </div>

            {/* Data Center */}
            <div className="mt-3">
              <div className="text-sm">Trung tâm dữ liệu</div>
              <FormControl name="data_center">
                <Input
                  className="mt-1 !max-w-none text-sm"
                  errorClassName="text-sm"
                />
              </FormControl>
            </div>

            {/* Submit Button */}
            <Button
              type="submit"
              label={portalData?.config ? "Cập nhật" : "Thêm"}
              containerClassName="mt-5 mx-auto"
              width="fit-content"
              className="px-6 text-sm"
              labelClassName="font-bold"
            />
          </Form>
        </Formik>
      </div>
    </Modal>
  );
}

export default CreateComputerConfigModal;
