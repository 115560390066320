import { createBrowserRouter } from "react-router-dom";
import SignIn from "./pages/sign-in";
import MainLayout from "./layout/main.layout";
import Home from "./pages/home";
import { PathnameUrl } from "./constants";
import BankManagement from "./pages/bank-management";
import { isAuthenticated } from "./loaders/authentication";
import RechargeManagement from "./pages/recharge-management";
import CategoryManagement from "./pages/category-management";
import PcManagement from "./pages/pc-management";
import PackageManagement from "./pages/package-management";
import LogManagement from "./pages/log-management";
import OrderManagement from "./pages/order-management";
import IPManagement from "./pages/ip-management";
import ImageManagement from "./pages/image-management";
import SshManagement from "./pages/ssh-management";
import UserGuideManagement from "./pages/user-guide-management";
import ComputerConfig from "./pages/computer-config-management";
import VpsManagement from "./pages/vps-management";
import OrderVpsManagement from "./pages/order-vps-management";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    loader: isAuthenticated,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: PathnameUrl.BANK_MANAGEMENT,
        element: <BankManagement />,
      },
      {
        path: PathnameUrl.RECHARGE_MANAGEMENT,
        element: <RechargeManagement />,
      },
      {
        path: PathnameUrl.COMPUTER_CONFIG_MANAGEMENT,
        element: <ComputerConfig />,
      },
      {
        path: PathnameUrl.VPS_MANAGEMENT,
        element: <VpsManagement />,
      },
      {
        path: PathnameUrl.CATEGORY_MANAGEMENT,
        element: <CategoryManagement />,
      },
      {
        path: PathnameUrl.PACKAGE_MANAGEMENT,
        element: <PackageManagement />,
      },
      {
        path: PathnameUrl.PC_MANAGEMENT,
        element: <PcManagement />,
      },
      {
        path: PathnameUrl.ORDER_MANAGEMENT,
        element: <OrderManagement />,
      },
      {
        path: PathnameUrl.ORDER_VPS_MANAGEMENT,
        element: <OrderVpsManagement />,
      },
      {
        path: PathnameUrl.LOG_MANAGEMENT,
        element: <LogManagement />,
      },
      {
        path: PathnameUrl.IP_MANAGEMENT,
        element: <IPManagement />,
      },
      {
        path: PathnameUrl.IMAGE_MANAGEMENT,
        element: <ImageManagement />,
      },
      {
        path: PathnameUrl.SSH_MANAGEMENT,
        element: <SshManagement />,
      },
      {
        path: PathnameUrl.USER_GUIDE_MANAGEMENT,
        element: <UserGuideManagement />,
      },
    ],
  },
  {
    path: "/login",
    element: <SignIn />,
    loader: isAuthenticated,
  },
]);

export default router;
