import { PortalDialogProps } from "@app/services/modal.service";
import Modal from "../modal/modal.component";
import { Form, Formik, FormikContextType } from "formik";
import { FormControl } from "../form-control";
import Button from "../button";
import Input from "../input";
import { createRef, useEffect, useState } from "react";
import useObservable from "@core/hooks/use-observable.hook";
import { CustomSelect } from "../select/custom-select";
import TriangleDownArrow from "../select/triangle-down-arrow";
import { NormalSelectOptions } from "../select/select.type";
import { createVpsValidationSchema } from "@app/validations";
import { VPSInitialValues } from "@app/types";
import VPSService from "@app/services/http/vps.service";
import { VPS_OS, VPS_Status, VPS_StatusLabel } from "@app/constants/vps.const";
import ComputerConfigService from "@app/services/http/computer.config.service";

function CreateVPSModal({ portalDialogRef, portalData }: PortalDialogProps) {
  const formRef = createRef<FormikContextType<VPSInitialValues>>();

  const [configs, setConfigs] = useState<NormalSelectOptions>([]);
  const [selectOs] = useState<NormalSelectOptions>([
    {
      label: VPS_OS.UBUNTU,
      value: VPS_OS.UBUNTU,
    },
    {
      label: VPS_OS.WINDOW_SERVER,
      value: VPS_OS.WINDOW_SERVER,
    },
  ]);
  const [selectStatus] = useState<NormalSelectOptions>([
    {
      label: VPS_StatusLabel[VPS_Status.ALREADY],
      value: VPS_Status.ALREADY,
    },
    {
      label: VPS_StatusLabel[VPS_Status.ON_RENTAL],
      value: VPS_Status.ON_RENTAL,
    },
    {
      label: VPS_StatusLabel[VPS_Status.END],
      value: VPS_Status.END,
    },
  ]);

  const { subscribeUntilDestroy } = useObservable();

  const initialValues = {
    ip_remote: portalData?.vps?.ip_remote || "",
    ssh: portalData?.vps?.ssh || "",
    user: portalData?.vps?.user || "",
    pass: portalData?.vps?.pass || "",
    os: portalData?.vps?.os || VPS_OS.UBUNTU,
    status: portalData?.vps?.status || VPS_Status.ALREADY,
    config: portalData?.vps?.config?._id || "",
  };

  useEffect(() => {
    subscribeUntilDestroy(ComputerConfigService.getConfigs(), (configs) => {
      if (configs.length) {
        const configsDropdown: NormalSelectOptions = configs.map((config) => ({
          label: config.config_code,
          value: config._id,
        }));

        setConfigs(configsDropdown);
      } else {
        setConfigs([]);
      }
    }); 
  }, []);

  const handleSubmit = (values: VPSInitialValues) => {
    const vpsRequest: VPSInitialValues = {
      ip_remote: values.ip_remote,
      ssh: values.ssh,
      user: values.user,
      pass: values.pass,
      os: values.os,
      status: values.status,
      config: values.config,
    };

    if (portalData?.vps) {
      //nếu có truyền giá trị vps thì update
      subscribeUntilDestroy(
        VPSService.updateVPS(portalData.vps?._id as string, vpsRequest),
        (vps) => {
          portalDialogRef.close({ vps });
        }
      );
    } else {
      subscribeUntilDestroy(
        VPSService.createVPS(values.config as string, vpsRequest),
        (vps) => {
          portalDialogRef.close({ vps });
        }
      );
    }
  };

  return (
    <Modal onCancel={portalDialogRef.close} buttonCancelInChildren>
      <div className="w-[300px] sm:w-[500px] p-5">
        <div className="font-bold text-xl">VPS</div>
        <Formik
          initialValues={initialValues}
          validationSchema={createVpsValidationSchema}
          innerRef={formRef}
          onSubmit={handleSubmit}
          validateOnChange
          validateOnBlur
        >
          <Form>
            <div className="flex justify-between gap-5">
              <div className="flex-1">
                <div className="flex gap-2 items-center justify-between">
                  <div className="mt-3 flex-1">
                    <div className="text-sm">User</div>
                    <FormControl name="user">
                      <Input
                        className="mt-1 !max-w-none text-sm"
                        errorClassName="text-sm"
                      />
                    </FormControl>
                  </div>
                  <div className="mt-3 flex-1">
                    <div className="text-sm">Pass</div>
                    <FormControl name="pass">
                      <Input
                        className="mt-1 !max-w-none text-sm"
                        errorClassName="text-sm"
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="flex gap-2 items-center justify-between">
                  <div className="mt-3 flex-1">
                    <div className="text-sm">OS</div>
                    <FormControl name="os">
                      <CustomSelect
                        options={selectOs}
                        width="100%"
                        className="!h-9 !border-[#3A466480] !rounded-lg"
                        optionContainerClassName="top-[34px] w-full"
                        optionClassName="!min-h-[35px] text-sm text-black whitespace-nowrap px-2"
                        labelSelectedClassName="text-black text-sm"
                        errorClassName="!text-[#D60000B2] text-sm"
                        arrow={<TriangleDownArrow />}
                        hasBgColorHover
                      />
                    </FormControl>
                  </div>
                  <div className="mt-3 flex-1">
                    <div className="text-sm">Status</div>
                    <FormControl name="status">
                      <CustomSelect
                        options={selectStatus}
                        width="100%"
                        className="!h-9 !border-[#3A466480] !rounded-lg"
                        optionContainerClassName="top-[34px] w-full"
                        optionClassName="!min-h-[35px] text-sm text-black whitespace-nowrap px-2"
                        labelSelectedClassName="text-black text-sm"
                        errorClassName="!text-[#D60000B2] text-sm"
                        arrow={<TriangleDownArrow />}
                        hasBgColorHover
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="flex gap-2 items-center justify-between">
                  <div className="mt-3 flex-1">
                    <div className="text-sm">IP Remote</div>
                    <FormControl name="ip_remote">
                      <Input
                        className="mt-1 !max-w-none text-sm"
                        errorClassName="text-sm"
                      />
                    </FormControl>
                  </div>
                  <div className="mt-3 flex-1">
                    <div className="text-sm">SSH</div>
                    <FormControl name="ssh">
                      <Input
                        className="mt-1 !max-w-none text-sm"
                        errorClassName="text-sm"
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="mt-3">
                  <div className="text-sm">Cấu hình</div>
                  <FormControl name="config">
                    <CustomSelect
                      options={configs}
                      width="100%"
                      className="!h-9 !border-[#3A466480] !rounded-lg"
                      optionContainerClassName="top-[34px] w-full"
                      optionClassName="!min-h-[35px] text-sm text-black whitespace-nowrap px-2"
                      labelSelectedClassName="text-black text-sm"
                      errorClassName="!text-[#D60000B2] text-sm"
                      arrow={<TriangleDownArrow />}
                      hasBgColorHover
                    />
                  </FormControl>
                </div>
              </div>
            </div>
            <Button
              type="submit"
              label={portalData?.vps ? "Cập nhật" : "Thêm"}
              containerClassName="mt-5 mx-auto"
              width="fit-content"
              className="px-6 text-sm"
              labelClassName="font-bold"
            />
          </Form>
        </Formik>
      </div>
    </Modal>
  );
}

export default CreateVPSModal;
