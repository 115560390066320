import {
  ComputerConfig,
  ComputerConfigInitialValues,
  UpdateComputerConfig,
} from "@app/types";
import HttpService from "@core/services/http/http.service";

class _ComputerConfigService {
  public getConfigs() {
    return HttpService.get<ComputerConfig[]>("/computer-configs");
  }

  public createComputerConfig(body: ComputerConfigInitialValues) {
    return HttpService.post<ComputerConfig>("/computer-configs", {
      body: {
        ...body,
      },
    });
  }

  public deleteOrUpdateComputerConfig(
    ComputerConfigId: string,
    bodyUpdate: UpdateComputerConfig
  ) {
    return HttpService.patch<ComputerConfig>(
      `/computer-configs/${ComputerConfigId}`,
      {
        body: {
          ...bodyUpdate,
        },
      }
    );
  }
}

const ComputerConfigService = new _ComputerConfigService();

export default ComputerConfigService;
