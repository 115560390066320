import { DEFAULT_PAGE } from "@app/constants";
import {
  VPSInitialValues,
  VPS,
  VPSResponse,
  UpdateVPSRequest,
} from "@app/types";
import HttpService from "@core/services/http/http.service";

class _VPSService {
  public getVPS(
    page = DEFAULT_PAGE,
    configId = "",
    status = "",
    os = "",
    vpsId = ""
  ) {
    return HttpService.get<VPSResponse>("/vps", {
      queryParams: {
        page,
        config_id: configId,
        status,
        os,
        vps_id: vpsId,
      },
    });
  }

  public createVPS(configId: string, createVps: VPSInitialValues) {
    return HttpService.post<VPS>(`/vps/${configId}`, {
      body: {
        ...createVps,
      },
    });
  }

  public updateVPS(VPSId: string, updateBody: UpdateVPSRequest) {
    return HttpService.patch<VPS>(`/vps/${VPSId}`, {
      body: {
        ...updateBody,
      },
    });
  }

  public deleteVPS(VPSId: string) {
    return HttpService.patch<VPS>(`/vps/${VPSId}`, {
      body: {
        is_deleted: true,
      },
    });
  }
}

const VPSService = new _VPSService();

export default VPSService;
